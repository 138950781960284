import * as React from 'react';
import { styled, useTheme } from '@mui/styles';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ListItemIcon } from '@mui/material';
import {
	CssBaseline, Switch, Box, Toolbar, List, Typography, Divider,
	IconButton, Badge, Container, Link, Button, Menu, MenuItem, useMediaQuery
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import KeyboardArrowDownnOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { useMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";

import { User_PicIcon } from '../assets/icons'
import { MainListItems } from './menuItems';
import models from '../config/models';
import { Hamburger_openedIcon } from '../assets/icons'
import { saveRecord } from '../Components/crud-helper';
import { UserContext } from '../contexts/userContext';
import { DataFiltersProvider } from '../contexts/dataFiltersContext';
import { ColorModeContext } from '../index';
import Copyright from '../Components/common/Copyright';

const drawerWidth = 360;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	...(open && {
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	})
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => {
	return {
		'& .MuiDrawer-paper': {
			position: 'relative',
			whiteSpace: 'nowrap',
			width: `${drawerWidth}px !important`,
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
			boxSizing: 'border-box',
			...(!open && {
				overflowX: 'hidden',
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen
				}),
				width: theme.spacing(7),
				[theme.breakpoints.up('sm')]: {
					width: theme.spacing(9)
				}
			}),
			backgroundColor: theme.palette.primary.main, //"#121733",
			color: theme.palette.primary.text.dark
		}
	};
});

const titles = {
	'/': 'Dashboard',
	'/dashboard': 'Dashboard',
	'/report': 'Reports',
	'/temperature': 'Temperature',
	'/door': 'Door',
	'/door-movement-history': 'Door Movement Activity History',
	'/door-movement-summary': 'Door Movement Count Summary',
	'/door-movement-average': 'Door Movement Average',
	'/system-health': 'System Health',
	'/alerts-history': 'Alerts History',
	'/my-alerts-history': 'My Alerts History',
	'/humidity-summary': 'Humidity Daily Summary',
	...models.reduce((acc, model) => {
		acc[`/${model.pathname || model.name}`] = model.title;
		return acc;
	}, {})
};

const ThemeToggler = () => {
	const colorMode = React.useContext(ColorModeContext);

	const [checked, setChecked] = React.useState(true);

	const handleChange = (event) => {
		setChecked(event.target.checked);
		colorMode.toggleColorMode();
	};

	return <Switch color="default" checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />;
};
function Layout(props) {
	const { instance } = useMsal();
	const [open, setOpen] = React.useState(true);
	const [userContext, setUserContext] = React.useContext(UserContext);
	const location = useLocation();
	const [title, setTitle] = React.useState(titles['/']);
	const navigate = useNavigate();
	const matches1100 = useMediaQuery('(min-width:1100px)');
	const [anchorEl, setAnchorEl] = React.useState(null);
	const menuOpen = Boolean(anchorEl);
	const { palette } = useTheme();

	React.useEffect(() => {
		setTitle(titles[location.pathname]);
	}, [location.pathname]);
	React.useEffect(() => {
		if (!matches1100) {
			setOpen(false);
			handleClose(false);
		} else {
			setOpen(true);
			handleClick(true);
		}
	}, [matches1100]);
	const toggleDrawer = () => {
		setOpen(!open);
	};
	React.useEffect(() => {
		if (!userContext?.email) {
			navigate('/sign-in', { replace: true, state: { pathName: location.pathname } });
		}
	}, [userContext, navigate, location.pathname]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	if (!userContext?.email) {
		return;
	}

	const handleLogout = () => {
		saveRecord({ api: `/logout`, values: { accountType: userContext?.accountType } }).then((response) => {
			if (response && response.success && response.logoutUrl) {
				window.location.href = response.logoutUrl;
			}
		});
	};
	const subDomain = window.location.hostname.split('.')[0];
	const isDips = subDomain.indexOf('reveal') === -1;
	return (
		<Box sx={{ display: 'flex', gap: 0 }}>
			<CssBaseline />
			<AppBar position="absolute" open={open} className="no-print">
				<Toolbar
					sx={{
						backgroundColor: palette.colors.header_bg,
						alignItems: 'center',
						padding: '14px 34px 8px!important',
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<div
						style={{
							width: '230px'
						}}
					>
						<img
							key={palette.mode}
							src={`data:image/png;base64,${userContext.appLogo}`}
							alt="Jam Labs Logo"
							style={{ width: 220, height: 45, objectFit: 'contain' }}
						/>
					</div>
					<div
						style={{
							flex: 1,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between'
						}}
					>
						<div>
							{title && (
								<p
									style={{
										fontWeight: '400',
										fontSize: '16px',
										color: palette.fixed.white
									}}
								>
									{isDips ? 'DIPS' : ''} {title}
								</p>
							)}
						</div>
						<div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
							<div>
								<ThemeToggler />
							</div>
							<span onClick={() => navigate('/notifications')}>
								<Badge badgeContent={`${userContext.alerts}`} color="error" style={{ cursor: 'pointer' }}>
									<NotificationsNoneOutlinedIcon sx={{ fontSize: '22px', color: palette.fixed.white }} />
								</Badge>
							</span>
							<div style={{ display: 'flex', gap: 12, alignItems: 'center', marginLeft: 16 }}>
								<User_PicIcon color={palette.fixed.white} />
								<Button
									id="basic-button"
									aria-controls={menuOpen ? 'basic-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={menuOpen ? 'true' : undefined}
									onClick={handleClick}
									sx={{
										color: palette.fixed.white,
										display: 'flex',
										alignItems: 'center',
										gap: 1,
										m: 0,
										p: 0,
										fontFamily: 'Comic Sans MS, Comic Sans'
									}}
								>
									<p
										style={{
											fontWeight: '300',
											fontSize: 11,
											textTransform: 'capitalize',
											color: palette.fixed.white
										}}
									>
										Hello,
									</p>
									<p
										style={{
											fontWeight: '600',
											fontSize: 13,
											textTransform: 'capitalize',
											color: palette.fixed.white
										}}
									>
										{userContext?.firstName}
									</p>
									<span style={{ margin: '0px 0px -8px -2px' }}>
										<KeyboardArrowDownnOutlinedIcon />
									</span>
								</Button>
								<Menu
									id="basic-menu"
									anchorEl={anchorEl}
									open={menuOpen}
									onClose={handleClose}
									PaperProps={{
										elevation: 0,
										sx: {
											overflow: 'visible',
											filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
											backgroundColor: palette.primary.light_2,
											border: palette.primary.text.medium,
											mt: 1.5,
											'& .MuiAvatar-root': {
												width: 32,
												height: 32,
												ml: -0.5,
												mr: 1
											},
											'&:before': {
												content: '""',
												display: 'block',
												position: 'absolute',
												top: 0,
												right: 14,
												width: 10,
												height: 10,
												bgcolor: palette.primary.light_2,
												transform: 'translateY(-50%) rotate(45deg)',
												border: palette.primary.text.medium,
												borderBottom: 'none',
												borderRight: 'none',
												zIndex: 3
											},
											'.css-7dw2lq-MuiButtonBase-root-MuiMenuItem-root:hover': {
												backgroundColor: palette.primary.text.medium
											}
										}
									}}
									transformOrigin={{ horizontal: 'right', vertical: 'top' }}
									anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
									MenuListProps={{
										'aria-labelledby': 'basic-button'
									}}
									sx={{ p: 0, m: 0 }}
								>
									{/* <MenuItem sx={{ p: 0, m: 0, pl: 2, pr: 2 }}>
                                    <ListItemIcon>
                                        <AccountBoxOutlinedIcon sx={{ color: palette.primary.text.dark }} fontSize="small" />
                                    </ListItemIcon>
                                    <p style={{ fontWeight: "300", fontSize: 12, marginLeft: -4, color: palette.primary.text.dark }}>My Profile</p>
                                </MenuItem> */}
									<MenuItem sx={{ p: 0, m: 0, pl: 2, pr: 2 }} onClick={handleLogout}>
										<ListItemIcon>
											<ExitToAppOutlinedIcon
												sx={{ color: palette.primary.text.dark }}
												fontSize="small"
											/>
										</ListItemIcon>
										<p
											style={{
												fontWeight: '300',
												fontSize: 12,
												marginLeft: -4,
												color: palette.primary.text.dark
											}}
										>
											Logout
										</p>
									</MenuItem>
								</Menu>
							</div>
						</div>
					</div>
				</Toolbar>
			</AppBar>

			<div
				style={{
					padding: palette.mode == 'dark' ? '100px 0px 0px 20px' : '80px 0px 0px 0px',
					background: palette.primary.dark,
					color: palette.primary.text.dark,
					height: '100vh'
				}}
			>
				<Drawer
					variant="permanent"
					open={open}
					className="no-print"
					sx={{
						height: palette.mode == 'dark' ? 'calc(100vh - 100px)' : 'calc(100vh - 80px)',
						boxShadow: '0px 0px 2px #00000046',
						[`& .MuiDrawer-paper`]: {
							width: (theme) => `${open ? 320 : 56}px !important`,
							boxSizing: 'border-box'
						}
					}}
				>
					<Toolbar
						sx={{
							ml: 0,
							backgroundColor: palette.primary.main,
							p: 0
						}}
						onClick={toggleDrawer}
						style={{ margin: 0 }}
					>
						<IconButton>
							<span
								style={{
									transition: 'all 0.4s ease',
									...(open
										? {
											transform: 'rotate(0deg)'
										}
										: {
											transform: 'rotate(180deg)'
										}),
									marginLeft: '-16px'
								}}
							>
								<Hamburger_openedIcon color={palette.primary.text.dark} />
							</span>
						</IconButton>
					</Toolbar>
					<Divider sx={{ backgroundColor: palette.primary.text.medium }} />
					<List component="nav">
						<MainListItems isMenuOpen={open} />
						{/* <Divider sx={{ my: 1 }} /> */}
					</List>
					{/* {open ? <Copyright /> : null} */}
				</Drawer>
			</div>

			<Box
				component="main"
				className="print-overflow"
				sx={{
					backgroundColor: palette.primary.dark,
					flexGrow: 1,
					height: '100vh',
					overflow: 'auto'
				}}
			>
				<Toolbar />
				{title && (
					<Typography
						component="h1"
						variant="h6"
						color="inherit"
						noWrap
						sx={{ flexGrow: 1, color: palette.primary.text.dark }}
						className="only-print-content title-center"
					>
						{userContext?.appName} {title}
					</Typography>
				)}
				<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
					<DataFiltersProvider>
						<Outlet />
					</DataFiltersProvider>
				</Container>
				<Copyright
					textProps={{
						sx: {
							fontWeight: '500',
							paddingBottom: 4,
							fontSize: '0.8em',
							opacity: 0.6,
							color: palette.primary.text.dark
						},
						variant: 'body2',
						color: palette.primary.text.dark,
						align: 'center'
					}}
					linkProps={{
						style: { color: 'inherit' }
					}}
				/>
			</Box>
		</Box>
	);
}

export default Layout;
