const alertTypes = [
    { label: 'Value', value: 1 },
    { label: 'Count', value: 2 }
];

const days = [
    { label: 'Sunday', value: 0 },
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 }
];

const hideField = ({ activeRecord, formValues, column }, rename) => {
    const { lookups = {} } = activeRecord || {};
    const sensorTypeId = formValues && formValues["sensorTypeId"];
    const alertType = formValues && formValues["alertTypeId"];
    const { sensorType = [] } = lookups;
    const doorSensorType = sensorType.filter(lookup => lookup.value === sensorTypeId)[0] || {};
    column.header = (rename && alertType === alertTypes[0].value && doorSensorType.isDuration) ? "Duration" : (rename ? "Max" : "Min");
    return { isHide: rename ? false : doorSensorType.isDuration, column };
}

const config = {
    title: 'Alert Definition',
    defaultSort: 'sensorTypeId desc',
    linkColumn: 'sensorTypeId',
    module: 'alertDefinition',
    columns: [
        { field: "alertName", width: 100, header: "Alert Name", minLength: 2, maxLength: 150, width: 200, hideable: false },
        { field: "sensorTypeId", header: "Sensor Type", lookup: "sensorType", required: true, width: 150, layout: "column" },
        { field: "alertTypeId", header: "Alert Type", width: 80, lookup: alertTypes, minValue: 0, maxValue: 2, filterable: false, sortable: false, layout: "column" },
        {
            field: "min", header: "Min", type: 'number', minValue: -100, maxValue: 100, decimal: true, width: 60,
            hideConfig: {
                onHide: function ({ activeRecord, formValues, column }) {
                    return hideField({ activeRecord, formValues, column }, false)
                }
            }, layout: "column"
        },
        {
            field: "max", header: "Max", type: 'number', minValue: -100, maxValue: 100, decimal: true, width: 60,
            hideConfig: {
                onHide: function ({ activeRecord, formValues, column }) {
                    return hideField({ activeRecord, formValues, column }, true)
                }
            }, layout: "column"
        },
        { field: "days", header: "Days", width: 200, lookup: days, multiSelect: true, filterable: false, sortable: false },
        { field: "inCase", header: "In Case", width: 80, type: "boolean", layout: "column" },
        { field: "requiresComment", header: "Requires Comment", width: 150, type: "boolean", layout: "column" },
        { field: "requiresAcknowledgment", header: "Requires Acknowledgment", width: 150, type: "boolean", },
        { field: "startTime", header: "Start time", required: true, width: 100, type: "time", isUtc: true, layout: "column" },
        { field: "endTime", header: "End time", required: true, width: 100, type: "time", isUtc: true, layout: "column" },
        { field: "to", header: "To", required: true, width: 250 },
        { field: "escalationOneTo", header: "Next Escalation To", width: 250, type: 'string', required: false },
        { field: "escalationOneTime", header: "Next Level Time (Minutes)", type: 'number', width: 150, required: false ,minValue: 0, maxValue: 100},
        { field: "escalationTwoTo", header: "Next Escalation To", width: 250, type: 'string', required: false },
        { field: "escalationTwoTime", header: "Next Level Time (Minutes)", type: 'number', width: 150, required: false,minValue: 0, maxValue: 100 },
        { field: "isActive", type: 'boolean', defaultValue: false },
    ],
    relations: [
        'alertDefinitionSensor'
    ]
};

export default config;
