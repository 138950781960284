import { Container, Grid, Typography, Paper, Box } from '@mui/material';
import { dateFormats } from 'dips-models';
import { useTheme } from '@mui/styles';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';

import { api as apiDefs } from '../config/authConfig';
import { getList } from '../Components/crud-helper';
import { useDataFilter } from '../contexts/dataFiltersContext';
import { UserContext } from '../contexts/userContext';
import Filters from '../Components/Filters';
import Loader from '../Components/loader';
import util from '../util';
import MultiLineChart from '../Components/common/MultiLineChart';

const { dateFormatHM: xAxisDateFormat, reportDurationDateFormat, reportCreatedDateFormat } = dateFormats;
const shortDateFormat = 'YYYY-MM-DD';
const checkshortDateFormat = 'MM-DD-YYYY';

const Report = () => {
	const [userContext] = React.useContext(UserContext);
	const [chartData, setChartData] = useState([]);
	const [label, setLabel] = useState({ roomLabel: '', sensorLabel: '', hospitalLabel: '' });
	const { palette } = useTheme();
	const [hospitalsFilterData, setHospitalsFilterData] = useState([]);
	const [roomsFilterData, setRoomsFilterData] = useState([]);
	const [sensorFilterData, setSensorFilterData] = useState([]);
	const [parentFilters, setParentFilters] = useDataFilter({
		Date: {
			operator: 'between',
			startDate: dayjs().subtract(6, 'day').format(shortDateFormat),
			endDate: dayjs().format(shortDateFormat),
			checkStartDate: dayjs().subtract(6, 'day').format(checkshortDateFormat),
			checkEndDate: dayjs().format(checkshortDateFormat)
		}
	});
	const extraParams = {
		report: 'Report',
		indexName: 'telemetry',
		valueField: 'Data'
	};
	const [isLoading, setIsLoading] = useState(false);

	const printHospitalVal = util.printHospital(hospitalsFilterData, parentFilters);

	const printRoomVal = util.printRoom(roomsFilterData, parentFilters);

	const printSensorVal = util.printSensor(sensorFilterData, parentFilters);
	useEffect(() => {
		let parentF = { ...parentFilters };
		getList({
			page: 0,
			pageSize: 10,
			sortModel: [],
			gridColumns: [{ lookup: 'sensor' }],
			api: `${apiDefs.base}/telemetry/summary`,
			setIsLoading,
			setData: (data) => {
				if (data.rowCount > 0) {
					const startDate = dayjs(parentFilters.Date.startDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
					const endDate = dayjs(parentFilters.Date.endDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
					for (const rec in data.rows) {
						data.rows[rec].Data.sort(function (a, b) {
							return new Date(a.Date) - new Date(b.Date);
						});

						data.rows[rec].Data = data.rows[rec].Data.map((item) => {
							if (data.rows[rec].isDuration) {
								return {
									Date: dayjs.utc(new Date(item.Date)).format("YYYY-MM-DD"),
									Duration: item.range,
									Count: item.count,
									Time: item.duration,
									sensorId: item.sensorId
								};
							} else {
								return {
									Date: item.Date,
									min: item.min,
									max: item.max,
									Data: item.Data,
									sensorId: item.sensorId
								};
							}
						});
						// const startCheck = data.rows[rec].Data.filter(ele => {
						// 	return ele.date === parentFilters.Date.startDate
						// });
						// const endCheck = data.rows[rec].Data.filter(ele => {
						// 	return ele.date === parentFilters.Date.endDate
						// });
						// if (startCheck.length === 0) {
						// 	data.rows[rec].Data.unshift({ Date: startDate })
						// }
						// if (endCheck.length === 0) {
						// 	data.rows[rec].Data.push({ Date: endDate })
						// }
					}
					setChartData(data.rows);
				} else {
					setChartData([]);
				}
			},
			modelConfig: { formDef: [] },
			parentFilters: {
				...parentF
			},
			action: 'report',
			extraParams: {...extraParams, isChartData: true}
		});
	}, [parentFilters]);

	const getHospitalNames = () => {
		let hospital = [];
		Object.keys(chartData).map((sensorKey) => {
			const { hospitalName } = chartData[sensorKey];
			if (hospital.indexOf(hospitalName) == -1) {
				hospital.push(hospitalName);
			}
		});
		return hospital.join(',');
	};

	const hospitalNames = getHospitalNames();
	return (
		<>
			<Loader isLoading={isLoading} />
			<Container
				maxWidth="lg"
				sx={{
					mt: 5,
					mb: 4,
					backgroundColor: palette.primary.light_1,
					padding: '26px 20px 10px 0px'
				}}
			>
				<Filters
					setParentFilters={setParentFilters}
					parentFilters={parentFilters}
					setLabel={setLabel}
					label={label}
					hospitalsFilterData={hospitalsFilterData}
					setHospitalsFilterData={setHospitalsFilterData}
					roomsFilterData={roomsFilterData}
					setRoomsFilterData={setRoomsFilterData}
					sensorFilterData={sensorFilterData}
					setSensorFilterData={setSensorFilterData}
				/>
				<Grid row>
					<Grid spacing={3} sx={{ mt: 3 }}>
						<Box className="only-print-content print-pd0" sx={{ p: 1 }}>
							<Box display="flex" justifyContent="center" alignItems="center" mb={1}>
								<img src={`data:image/png;base64,${userContext.appLogo2}`} style={{ width: 200, height: 40 }} alt={`${userContext?.docTitle}`} />
							</Box>
							<Box
								sx={{ fontWeight: 'bold', textDecoration: 'underline' }}
								mb={5}
								pt={2}
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								Sensor History Regulatory Report
							</Box>
							<Box className="report-header-container">
								<Box>Hospital: {printHospitalVal}</Box>
								<Box>
									Dates: {dayjs(parentFilters.Date.startDate).format(reportDurationDateFormat)} to{' '}
									{dayjs(parentFilters.Date.endDate).format(reportDurationDateFormat)}
								</Box>
								<Box>Created: {dayjs().format(reportCreatedDateFormat)}</Box>
							</Box>
						</Box>
						<Paper
							sx={{
								display: 'flex',
								flexDirection: 'column',
								backgroundColor: 'transparent',
								gap: 10
							}}
							className="print-report-container"
						>
							{Object.keys(chartData).map((sensorKey) => {
								const { Data, isDuration, unit, roomName, hospitalName, description } = chartData[sensorKey];
								return (
									<div key={sensorKey} className="MuiGrid-container-print">
										<Typography
											style={{
												backgroundColor: 'transparent',
												fontWeight: '300',
												color: palette.primary.text.dark
											}}
											component="h2"
											variant="h6"
											color="inherit"
											textAlign="left"
											py={2}
										>
											History Chart - {printSensorVal ? printSensorVal : 'All'}{' '}
											{` | Hospital: ${printHospitalVal ? printHospitalVal : 'All'}`}{' '}
											{roomName ? `| Room: ${printRoomVal ? printRoomVal : 'All'}` : ''}
										</Typography>
										<MultiLineChart
											data={Data ?? []}
											sensorInfo={{ sensorId: sensorKey, sensorName: sensorKey }}
											isDuration={isDuration}
											unit={unit}
											yAxisLabel1={description}
										/>

									</div>
								);
							})}
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

export default Report;