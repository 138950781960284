import { React, useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { Grid, Box } from '@mui/material';
import { useTheme } from '@mui/styles';

import { loadLookups } from '../Components/crud-helper';
import { api as apiDefs } from '../config/authConfig';
import Filter from '../Components/common/Filter';
import CustomDateRangeInputs from './common/DateRangePicker';
import { dateFormats } from 'dips-models';

// const { date: shortDateFormat } = dateFormats;
const shortDateFormat = 'YYYY-MM-DD';
const Filters = (props) => {
	const { setParentFilters, parentFilters, setLabel, label, showSensorTypeFilter = true, lookupParams, roomsFilterData, setRoomsFilterData,sensorFilterData, setSensorFilterData,hospitalsFilterData, setHospitalsFilterData} = props;
	const [sensorTypeFilterData, setSensorTypeFilterData] = useState('');
	const hospitalFilter = useMemo(() => {
		const filter = Array.isArray(parentFilters?.hospitalId)
			? [...parentFilters.hospitalId]
			: parentFilters?.hospitalId
			? [parentFilters?.hospitalId]
			: [];
		return filter;
	}, [parentFilters?.hospitalId]);
	const roomFilter = useMemo(() => {
		const filter = Array.isArray(parentFilters?.roomId)
			? [...parentFilters.roomId]
			: parentFilters?.roomId
			? [parentFilters?.roomId]
			: [];
		return filter;
	}, [parentFilters?.roomId]);

	const sensorFilter = useMemo(() => {
		const filter = Array.isArray(parentFilters?.sensorId)
			? [...parentFilters.sensorId]
			: parentFilters?.sensorId
			? [parentFilters?.sensorId]
			: [];
		return filter;
	}, [parentFilters?.sensorId]);

	const sensorTypeFilter = useMemo(() => {
		const filter = parentFilters?.sensorTypeId || '';
		return filter;
	}, [parentFilters?.sensorTypeId]);

	const dateTimeRange = [
		parentFilters?.Date?.startDate ? dayjs(parentFilters.Date?.startDate) : dayjs().subtract(6, 'day'),
		parentFilters?.Date?.endDate ? dayjs(parentFilters.Date?.endDate) : dayjs()
	];
	const [lookups, setLookupParams] = useState(
		lookupParams
			? [...lookupParams]
			: [{ lookupType: 'hospital' }, { lookupType: 'room' }, { lookupType: 'sensor' }, { lookupType: 'sensorType' }]
	);
	const { palette } = useTheme();

	useEffect(() => {
		requestLookups({ lookups });
	}, []);

	const requestLookups = ({ lookups }) => {
		loadLookups({
			lookups: lookups,
			api: `${apiDefs.base}/loadlookups`,
			setActiveRecord: (data) => {
				if (data && data.result) {
					updateLookups(data.result);
				}
			}
		});
	};
	const updateLookups = (result) => {
		const { hospital, room, sensor, sensorType } = result;
		if (hospital) {
			hospital.unshift({ label: 'All', value: 'All' });
			setHospitalsFilterData(hospital);
		}
		if (sensor) {
			sensor.unshift({ label: 'All', value: 'All' });
			setSensorFilterData(sensor);
		}
		if (room) {
			room.unshift({ label: 'All', value: 'All' });
			setRoomsFilterData(room);
		}
		if (sensorType) {
			sensorType.unshift({ label: 'All', value: 'All' });
			setSensorTypeFilterData(sensorType);
		}
	};

	const getSelectedRecords = (filteredData, dataValues) => {
		let toReturn = [];
		for (let i = 0; i < filteredData.length; i++) {
			for (let j = 0; j < dataValues.length; j++) {
				if (filteredData[i].value === dataValues[j]) {
					toReturn.push(filteredData[i].label);
				}
			}
		}
		return toReturn;
	};
	const handleRoomFilterChange = (event) => {
		let dataValues = event.target.value;
		const sensorLookup = lookups.find((a) => a.lookupType === 'sensor');
		if (dataValues.length === 0) {
			// setRoomFilter([]);
			delete parentFilters.roomId;
			delete sensorLookup.where?.roomId;
			setLookupParams(lookups);
			requestLookups({ lookups: lookups.filter((a) => a.lookupType === 'sensor') });
			setParentFilters({ ...parentFilters });
			return;
		}
		const selectAll = dataValues.includes('All');
		const isLastSelectAll = dataValues[dataValues.length - 1] === 'All';
		if (selectAll && (dataValues.length === 1 || isLastSelectAll)) {
			delete parentFilters.roomId;
			// setRoomFilter(['All']);
			if (setLabel) {
				setLabel({ ...label, roomLabel: 'All' });
			}
			delete sensorLookup.where?.roomId;
			setLookupParams(lookups);
			requestLookups({ lookups: lookups.filter((a) => a.lookupType === 'sensor') });
			setParentFilters({ ...parentFilters });
		} else {
			dataValues = dataValues.filter((item) => item !== 'All');
			if (setLabel) {
				let selectedRooms = getSelectedRecords(roomsFilterData, dataValues);
				setLabel({ ...label, roomLabel: selectedRooms.join(',') });
			}
			if (!sensorLookup.where) {
				sensorLookup.where = {};
			}
			sensorLookup.where['roomId'] = dataValues;
			setLookupParams(lookups);
			requestLookups({ lookups: lookups.filter((a) => a.lookupType === 'sensor') });

			// setRoomFilter(dataValues);
			setParentFilters({ ...parentFilters, roomId: dataValues });
		}
	};

	const handleSensorFilterChange = (event) => {
		let dataValues = event.target.value;
		if (dataValues.length === 0) {
			// setSensorFilter([]);
			delete parentFilters.sensorId;
			setParentFilters({ ...parentFilters });
			return;
		}
		const selectAll = dataValues.includes('All');
		const isLastSelectAll = dataValues[dataValues.length - 1] === 'All';
		if (selectAll && (dataValues.length === 1 || isLastSelectAll)) {
			delete parentFilters.sensorId;
			// setSensorFilter(['All']);
			if (setLabel) {
				setLabel({ ...label, sensorLabel: 'All' });
			}
			setParentFilters({ ...parentFilters });
		} else {
			dataValues = dataValues.filter((item) => item !== 'All');
			if (setLabel) {
				let selectedSensors = getSelectedRecords(sensorFilterData, dataValues);
				setLabel({ ...label, sensorLabel: selectedSensors.join(',') });
			}
			// setSensorFilter(dataValues);
			setParentFilters({ ...parentFilters, sensorId: dataValues });
		}
	};

	const handleSensorTypeFilterChange = (event) => {
		let dataValues = event.target.value;
		const isArrayType = Array.isArray(dataValues);
		const selectAll = dataValues.includes('All');
		const isLastSelectAll = isArrayType ? dataValues[dataValues.length - 1] === 'All' : true;
		// setSensorTypeFilter(dataValues);
		const sensorLookup = lookups.find((a) => a.lookupType === 'sensor');
		if (selectAll && (dataValues.length === 1 || isLastSelectAll)) {
			delete parentFilters.sensorTypeId;
			delete sensorLookup.where?.sensorTypeId;
			setParentFilters({ ...parentFilters });
		} else {
			dataValues = isArrayType ? dataValues.filter((item) => item !== 'All') : dataValues;
			if (!sensorLookup.where) {
				sensorLookup.where = {};
			}
			sensorLookup.where['sensorTypeId'] = dataValues;
			setLookupParams(lookups);
			requestLookups({ lookups: lookups.filter((a) => a.lookupType === 'sensor') });

			setParentFilters({ ...parentFilters, sensorTypeId: dataValues });
		}
	};

	const handleHospitalFilterChange = (event) => {
		let dataValues = event.target.value;
		const roomLookup = lookups.find((a) => a.lookupType === 'room');
		const sensorLookup = lookups.find((a) => a.lookupType === 'sensor');
		if (dataValues.length === 0) {
			//setHospitalFilter([]);
			delete parentFilters.hospitalId;
			setParentFilters({ ...parentFilters });
			delete roomLookup.where;
			if(sensorLookup.include){
				delete sensorLookup.include;
			}
			setLookupParams(lookups);
			requestLookups({ lookups: lookups.filter((a) => a.lookupType !== 'hospital') });
			return;
		}
		const selectAll = dataValues.includes('All');
		const isLastSelectAll = dataValues[dataValues.length - 1] === 'All';
		if (selectAll && (dataValues.length === 1 || isLastSelectAll)) {
			delete parentFilters.hospitalId;
			// setHospitalFilter(['All']);
			if (setLabel) {
				setLabel({ ...label, hospitalLabel: 'All' });
			}
			delete roomLookup.where;
			if(sensorLookup.include){
				delete sensorLookup.include;
			}
			setLookupParams(lookups);
			requestLookups({ lookups: lookups.filter((a) => a.lookupType !== 'hospital') });
			setParentFilters({ ...parentFilters });
		} else {
			dataValues = dataValues.filter((item) => item !== 'All');
			if (setLabel) {
				let selectedHospitals = getSelectedRecords(hospitalsFilterData, dataValues);
				setLabel({ ...label, hospitalLabel: selectedHospitals.join(',') });
			}
            delete parentFilters.roomId;
            delete parentFilters.sensorId;
			roomLookup.where = { hospitalId: dataValues };
			sensorLookup.include = [{ model: 'room', as: 'room', where: { '$room.hospitalId$': dataValues } }];
			setLookupParams(lookups);
			requestLookups({ lookups: lookups.filter((a) => a.lookupType !== 'hospital') });
			// setHospitalFilter(dataValues);
			setParentFilters({ ...parentFilters, hospitalId: dataValues });
		}
	};

	const handleTimeRangeFilterChange = (event) => {
		if (!event[1]) {
			event[1] = dayjs();
		}
		if (event[0] && event[1]) {
			setParentFilters({
				...parentFilters,
				Date: {
					operator: 'between',
					startDate: event[0].format(shortDateFormat),
					endDate: event[1].format(shortDateFormat)
				}
			});
		}
	};

	const clearFilters = () => {
		window.location.reload();
		// setRoomFilter([]);
		// setSensorFilter([]);
		// setSensorTypeFilter('');
		// setHospitalFilter([]);
		if (setLabel) {
			setLabel({ roomLabel: '', sensorLabel: '' });
		}
		delete parentFilters.sensorId;
		delete parentFilters.sensorTypeId;
		delete parentFilters.roomId;
		delete parentFilters.hospitalId;
		handleTimeRangeFilterChange([dayjs().subtract(6, 'day'), dayjs()]);
	};

	const onPrintClick = () => {
		window.print();
	};

	return (
		<Grid
			container
			columnSpacing={{ xs: 2, sm: 2, md: 5.5 }}
			rowSpacing={{ xs: 2, sm: 2, md: 5.5 }}
			className="no-print"
		>
			<Grid item lg={3} md={4} sm={6} xs={12}>
				<Filter
					label="Hospital"
					id="hospital"
					options={hospitalsFilterData}
					handleChange={handleHospitalFilterChange}
					multiple
					selectedValue={hospitalFilter}
				/>
			</Grid>

			<Grid item lg={3} md={4} sm={6} xs={12}>
				<Filter
					label="Room"
					id="room"
					options={roomsFilterData}
					handleChange={handleRoomFilterChange}
					multiple
					selectedValue={roomFilter}
				/>
			</Grid>

			{showSensorTypeFilter && (
				<Grid item lg={3} md={4} sm={6} xs={12}>
					<Filter
						label="Sensor Type"
						id="sensorType"
						options={sensorTypeFilterData}
						handleChange={handleSensorTypeFilterChange}
						selectedValue={sensorTypeFilter}
					/>
				</Grid>
			)}

			<Grid item lg={3} md={4} sm={6} xs={12}>
				<Filter
					label="Sensor"
					id="sensor"
					options={sensorFilterData}
					handleChange={handleSensorFilterChange}
					multiple
					selectedValue={sensorFilter}
				/>
			</Grid>

			<Grid item sm={6} xs={12}>
				<CustomDateRangeInputs id="date" value={dateTimeRange} setDateTimeRangeValue={handleTimeRangeFilterChange} />
			</Grid>

			<Grid item lg={3} md={4} sm={6} xs={12}>
				<div style={{ display: 'flex', alignItems: 'end' }}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							gap: 12,
							textAlign: 'center',
							marginTop: '-4px',
							width: '100%'
						}}
					>
						<p
							onClick={clearFilters}
							style={{
								whiteSpace: 'nowrap',
								border: `1.5px solid ${palette.colors.clear_btn}`,
								borderRadius: '0.2em',
								color: palette.colors.clear_btn,
								fontSize: '15px',
								padding: '9.5px 8px',
								opacity: 0.7,
								flex: 1,
								cursor: 'pointer'
							}}
						>
							CLEAR FILTER
						</p>
						<p
							onClick={onPrintClick}
							style={{
								background: palette.colors.print_btn,
								whiteSpace: 'nowrap',
								fontSize: '15px',
								boxShadow: '0px 5px 10px #1BCB7433',
								borderRadius: '0.2em',
								padding: '9.5px 9px',
								color: palette.fixed.white,
								flex: 1,
								cursor: 'pointer'
							}}
						>
							PRINT
						</p>
					</div>
				</div>
			</Grid>
		</Grid>
	);
};

export default Filters;
